<template>
  <van-row justify="center" class="page-wrapper">
    <van-col
      span="24"
      class="round-shadow-btn flex-display-fix container_width mt-15"
    >
      <div
        class="apply-loader itserve-info-message-new round-shadow-btn-new-new"
      >
        <span class="info-content-new">
          <slot>{{ $t("ShareAppBeforePutIndication.text") }}</slot>
        </span>
      </div>
    </van-col>
    <template v-if="facebookInstalled === true">
    <van-col span="24" class="itserve-center">
      <itserve-button
        :value="$t('ShareAppBeforePutIndication.button')"
        type="primary"
        @click="facebookShare"
        class="less-tall-btn mt-25"
        :icon="true"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="35" height="35" fill="white"
        style="margin-left: 50px">
          <path d="M14.5 3H11c-2.21 0-4 1.79-4 4v3H5v4h2v8h4v-8h3.5l.5-4h-4V7c0-.55.45-1 1-1h3V3z"/>
        </svg>
      </itserve-button>
    </van-col>
    </template>
    <template v-else>
      <van-col span="24" class="itserve-center">
        <itserve-button
            :value="$t('ShareAppBeforePutIndication.button')"
            type="primary"
            @click="socialShare"
            class="less-tall-btn mt-25"
        ></itserve-button>
      </van-col>
    </template>
  </van-row>
</template>
<script>
import ItserveButton from "@/components/forms/ItserveButton";
import Settings from "@/plugins/api/factories/Settings";
export default {
  name: "ShareAppBeforePutIndication",
  components: { ItserveButton },
  computed: {
    account() {
      return this.$store.state.Auth.account;
    },
    facebookInstalled() {
      return this.$store.state.Auth.nativeData.facebook_installed;
    }
  },
  data() {
    return {
      paymentUrl: null,
      marketUrl: "",
      marketFacebookUrl: "",
      appName: "",
    };
  },
  created() {
    this.nativeWaitForEvent("socialShareStatus", (params) => {
      if (params.status === true && this.$route.name === "ShareAppBeforePutIndication") {
        Settings.setShareFacebookStatus();
        Settings.setShareStatus();
        this.$store.commit("Auth/SET_USER_SHARE_STATUS", 1);
        this.$router.push({ name: "EasyPayAllServicePage" });
      }
    });
    this.getMarketUrl();
    this.getMarketFacebookUrl();
    this.shareAppName();
  },
  destroyed() {
    clearInterval(this.watchInterval);
  },
  methods: {
    shareAppName() {
      let id = this.$store.state.Auth.nativeData.id
      let locale = this.$store.state.Auth.locale;
      let text;

      switch (id) {
        case "zaporizhzhia.gas":
          text = this.getLocalizedText(locale, {
            uk: "Запоріжжя газ",
            ru: "Запорожье газ",
            en: "Zaporizhzhia gas",
          });
          break;
        case "lviv.gas":
          text = this.getLocalizedText(locale, {
            uk: "Львів газ",
            ru: "Львов газ",
            en: "Lviv gas",
          });
          break;
        case "volyn.gas":
          text = this.getLocalizedText(locale, {
            uk: "Волинь газ",
            ru: "Волынь газ",
            en: "Volyn gas",
          });
          break;
        case "kherson.gas":
          text = this.getLocalizedText(locale, {
            uk: "Херсон газ",
            ru: "Херсон газ",
            en: "Kherson gas",
          });
          break;
        case "ternopil.gas":
          text = this.getLocalizedText(locale, {
            uk: "Тернопіль газ",
            ru: "Тернополь газ",
            en: "Ternopil gas",
          });
          break;
        case "chernivtsi.gas":
          text = this.getLocalizedText(locale, {
            uk: "Чернівці газ",
            ru: "Черновцы газ",
            en: "Chernivtsi gas",
          });
          break;
        case "prykarpattya.gas":
          text = this.getLocalizedText(locale, {
            uk: "Прикарпаття газ",
            ru: "Прикарпатье газ",
            en: "Prykarpattya gas",
          });
          break;
        case "zakarpattya.gas":
          text = this.getLocalizedText(locale, {
            uk: "Закарпаття газ",
            ru: "Закарпатье газ",
            en: "Zakarpattya gas",
          });
          break;
        case "rivne.gas":
          text = this.getLocalizedText(locale, {
            uk: "Рівне газ",
            ru: "Ровно газ",
            en: "Rivne gas",
          });
          break;
        case "khmelnytskyi.gas":
          text = this.getLocalizedText(locale, {
            uk: "Хмельницький газ",
            ru: "Хмельницкий газ",
            en: "Khmelnytskyi gas",
          });
          break;
        case "vinnytsia.gas":
          text = this.getLocalizedText(locale, {
            uk: "Вінниця газ",
            ru: "Винница газ",
            en: "Vinnytsia gas",
          });
          break;
        case "cherkasy.gas":
          text = this.getLocalizedText(locale, {
            uk: "Черкаси газ",
            ru: "Черкассы газ",
            en: "Cherkasy gas",
          });
          break;
        case "chernihiv.gas":
          text = this.getLocalizedText(locale, {
            uk: "Чернігів газ",
            ru: "Чернигов газ",
            en: "Chernihiv gas",
          });
          break;
        case "mykolaiv.gas":
          text = this.getLocalizedText(locale, {
            uk: "Миколаїв газ",
            ru: "Николаев газ",
            en: "Mykolaiv gas",
          });
          break;
        case "dnipro.gas":
          text = this.getLocalizedText(locale, {
            uk: "Дніпро газ",
            ru: "Днепр газ",
            en: "Dnipro gas",
          });
          break;
        case "poltava.gas":
          text = this.getLocalizedText(locale, {
            uk: "Полтава газ",
            ru: "Полтава газ",
            en: "Poltava gas",
          });
          break;
        case "odesa.gas":
          text = this.getLocalizedText(locale, {
            uk: "Одеса газ",
            ru: "Одесса газ",
            en: "Odesa gas",
          });
          break;
        case "zhytomyr.gas":
          text = this.getLocalizedText(locale, {
            uk: "Житомир газ",
            ru: "Житомир газ",
            en: "Zhytomyr gas",
          });
          break;
        case "kirovograd.gas":
          text = this.getLocalizedText(locale, {
            uk: "Кіровоград газ",
            ru: "Кировоград газ",
            en: "Kirovohrad gas",
          });
          break;
        case "sumy.gas":
          text = this.getLocalizedText(locale, {
            uk: "Суми газ",
            ru: "Сумы газ",
            en: "Sumy gas",
          });
          break;
        case "kyiv.gas":
          text = this.getLocalizedText(locale, {
            uk: "Київ газ",
            ru: "Киев газ",
            en: "Kyiv gas",
          });
          break;
        case "kharkiv.gas":
          text = this.getLocalizedText(locale, {
            uk: "Харків газ",
            ru: "Харьков газ",
            en: "Kharkiv gas",
          });
          break;
        default:
          text = this.getLocalizedText(locale, {
            uk: "Харків газ",
            ru: "Харьков газ",
            en: "Kharkiv gas",
          });
      }
      console.log("LOCALE:", this.$store.state.Auth.locale)
      this.appName = text;
    },
    getLocalizedText(locale, links) {
      switch (locale) {
        case "uk":
          return links.uk;
        case "ru":
          return links.ru;
        case "en":
          return links.en;
        default:
          return links.uk; // значение по умолчанию, если язык не найден
      }
    },
    getMarketUrl() {
      let id;
      let url;

      id = this.$store.state.Auth.app_id;
      switch (id) {
        case "zaporizhzhia.gas":
          url = "https://billing.city/zaporizhzhia.gas";
          break;
        case "lviv.gas":
          url = "https://billing.city/lviv.gas";
          break;
        case "volyn.gas":
          url = "https://billing.city/volyn.gas";
          break;
        case "kherson.gas":
          url = "https://billing.city/kherson.gas";
          break;
        case "ternopil.gas":
          url = "https://billing.city/ternopil.gas";
          break;
        case "chernivtsi.gas":
          url = "https://billing.city/chernivtsi.gas";
          break;
        case "prykarpattya.gas":
          url = "https://billing.city/prykarpattya.gas";
          break;
        case "zakarpattya.gas":
          url = "https://billing.city/zakarpattya.gas";
          break;
        case "rivne.gas":
          url = "https://billing.city/rivne.gas";
          break;
        case "khmelnytskyi.gas":
          url = "https://billing.city/khmelnytskyi.gas";
          break;
        case "vinnytsia.gas":
          url = "https://billing.city/vinnytsia.gas";
          break;
        case "cherkasy.gas":
          url = "https://billing.city/cherkasy.gas";
          break;
        case "chernihiv.gas":
          url = "https://billing.city/chernihiv.gas";
          break;
        case "mykolaiv.gas":
          url = "https://billing.city/mykolaiv.gas";
          break;
        case "dnipro.gas":
          url = "https://billing.city/dnipro.gas";
          break;
        case "poltava.gas":
          url = "https://billing.city/poltava.gas";
          break;
        case "odesa.gas":
          url = "https://billing.city/odesa.gas";
          break;
        case "zhytomyr.gas":
          url = "https://billing.city/zhytomyr.gas";
          break;
        case "kirovograd.gas":
          url = "https://billing.city/kirovograd.gas";
          break;
        case "sumy.gas":
          url = "https://billing.city/sumy.gas";
          break;
        case "kyiv.gas":
          url = "https://billing.city/kyiv.gas";
          break;
        case "kharkiv.gas":
          url = "https://billing.city/kharkiv.gas";
          break;
        default:
          url = "https://billing.city";
      }
      this.marketUrl = url;
    },
    getMarketFacebookUrl() {
      let id;
      let url;

      id = this.$store.state.Auth.app_id;
      switch (id) {
        case "zaporizhzhia.gas":
          url = "https://billing.city/zaporizhzhia.gas";
          break;
        case "lviv.gas":
          url = "https://billing.city/lviv.gas";
          break;
        case "volyn.gas":
          url = "https://billing.city/volyn.gas";
          break;
        case "kherson.gas":
          url = "https://billing.city/kherson.gas";
          break;
        case "ternopil.gas":
          url = "https://billing.city/ternopil.gas";
          break;
        case "chernivtsi.gas":
          url = "https://billing.city/chernivtsi.gas";
          break;
        case "prykarpattya.gas":
          url = "https://billing.city/prykarpattya.gas";
          break;
        case "zakarpattya.gas":
          url = "https://billing.city/zakarpattya.gas";
          break;
        case "rivne.gas":
          url = "https://billing.city/rivne.gas";
          break;
        case "khmelnytskyi.gas":
          url = "https://billing.city/khmelnytskyi.gas";
          break;
        case "vinnytsia.gas":
          url = "https://billing.city/vinnytsia.gas";
          break;
        case "cherkasy.gas":
          url = "https://billing.city/cherkasy.gas";
          break;
        case "chernihiv.gas":
          url = "https://billing.city/chernihiv.gas";
          break;
        case "mykolaiv.gas":
          url = "https://billing.city/mykolaiv.gas";
          break;
        case "dnipro.gas":
          url = "https://billing.city/dnipro.gas";
          break;
        case "poltava.gas":
          url = "https://billing.city/poltava.gas";
          break;
        case "odesa.gas":
          url = "https://billing.city/odesa.gas";
          break;
        case "zhytomyr.gas":
          url = "https://billing.city/zhytomyr.gas";
          break;
        case "kirovograd.gas":
          url = "https://billing.city/kirovograd.gas";
          break;
        case "sumy.gas":
          url = "https://billing.city/sumy.gas";
          break;
        case "kyiv.gas":
          url = "https://billing.city/kyiv.gas";
          break;
        case "kharkiv.gas":
          url = "https://billing.city/kharkiv.gas";
          break;
        default:
          url = "https://billing.city";
      }
      this.marketFacebookUrl = url;
    },
    facebookShare() {
      this.nativeSendEvent("formatSocialShare", {
        text: this.$t("settings.title1") + this.appName + this.$t("settings.title2"),
        url: this.marketFacebookUrl,
        format: "shareViaFacebook",
      });
    },
    socialShare() {
      this.nativeSendEvent("socialShare", {
        text:
            this.$t("settings.title1") + this.appName + this.$t("settings.title2") + " " + this.marketUrl,
        subject: this.appName,
      });

      Settings.setShareStatus();
      this.$store.commit("Auth/SET_USER_SHARE_STATUS", 1);

      setTimeout(async () => {
        await this.$router.push({
          name: "EasyPayAllServicePage",
          params: { easyPayUrl: this.$route.params.easyPayUrl },
        });
      }, 10000);
    },
  },
};
</script>

<style scoped>

.itserve-info-message-new {
  margin-top: 15px;
  margin-bottom: 15px;
  color: #0b0b0b;
  font-size: 18px;
  text-align: justify;
  max-width: 337px;
}
.info-content-new {
  max-width: 317px;
  display: inline-block;
  text-align: center;
  padding: 10px;
  white-space: pre-line;
}

.app-theme-dark .round-shadow-btn-new-new {
  border-radius: 15px;
  -webkit-box-shadow: 0 6px 4px 0 rgba(34, 60, 80, 0.17);
  -moz-box-shadow: 0 6px 4px 0 rgba(34, 60, 80, 0.17);
  padding-bottom: 15px;
  background-color: #474545 !important;
  box-shadow: none !important;
  color: #fff;
}
</style>
