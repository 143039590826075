<template>
  <footer>
    <template v-if="supportRoutes.includes(currentRouteName)">
      <support-bottom-menu />
    </template>
    <template v-else>
      <template v-if="!supportRoutes.includes(currentRouteName)">
        <div class="support-helper-btn">
          <span class="support-text">{{ $t("components.footer.techSupport") }}</span>
        </div>
        <dialogFlowCX />
      </template>
    </template>
  </footer>
</template>

<script>
import SupportBottomMenu from "@/components/support/SupportBottomMenu";
import DialogFlowCX from "@/components/dialogFlowCX/dialogFlowCX";
import appBridge from "@/mixins/app-bridge";

export default {
  mixins: [appBridge],
  name: "AppFooter",
  data() {
    return {
      supportRoutes: ["Contacts", "WriteTicket", "SupportPage"],
    };
  },
  components: {
    SupportBottomMenu,
    DialogFlowCX,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style scoped>
footer {
  background-color: #fff;
}
.support-helper-btn {
  position: relative;
  height: 75px;
  display: inline-block;
  line-height: 75px;
  padding-left: 25px;
  padding-right: 25px;
  margin-left: 15px;
  border-radius: 5px;
}

.support-text {
  color: #1d1d1d;
}
</style>
