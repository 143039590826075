<template>
  <div id="dialogflow-container">
    <!-- Dialogflow виджет будет добавлен через скрипт -->
  </div>
</template>

<script>
export default {
  name: "DialogFlowCX",
  mounted() {
    this.loadDialogflowWidget();
  },
  methods: {
    loadDialogflowWidget() {
      const scriptUrl =
          "https://www.gstatic.com/dialogflow-console/fast/df-messenger/prod/v1/df-messenger.js";

      if (!document.querySelector(`script[src="${scriptUrl}"]`)) {
        console.log("Script not found, adding it...");
        const script = document.createElement("script");
        script.src = scriptUrl;
        script.crossOrigin = "anonymous";
        script.onload = () => {
          this.initializeDialogflow();
        };
        script.onerror = (error) =>
            console.error("Ошибка при загрузке скрипта", error);
        document.body.appendChild(script);
      } else {
        this.initializeDialogflow();
      }
    },
    initializeDialogflow() {
      const container = document.getElementById("dialogflow-container");

      const dfMessenger = document.createElement("df-messenger");
      dfMessenger.setAttribute("project-id", "chatcx-442216");
      dfMessenger.setAttribute("agent-id", "225f6af6-06b6-4c40-973f-348f372cef78");
      dfMessenger.setAttribute("language-code", "uk");
      dfMessenger.setAttribute("max-query-length", "-1");

      const chatBubble = document.createElement("df-messenger-chat-bubble");
      chatBubble.setAttribute("chat-title", "Чат");
      chatBubble.setAttribute("placeholder-text", "Пишіть сюди…");
      dfMessenger.appendChild(chatBubble);

      const style = document.createElement("style");
      style.textContent = `
        df-messenger {
          z-index: 999;
          position: fixed;
          --df-messenger-font-color: #000;
          --df-messenger-font-family: Google Sans;
          --df-messenger-chat-background: #f3f6fc;
          --df-messenger-message-user-background: #d3e3fd;
          --df-messenger-message-bot-background: #fff;
          bottom: 16px;
          right: 16px;
        }
      `;
      dfMessenger.appendChild(style);
      container.appendChild(dfMessenger);
    },
  },
};
</script>

<style scoped>
#dialogflow-container {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 999;
}
</style>
