<template>
  <van-row class="page-wrapper" justify="center">
    <van-col class="itserve-center mb-25" span="24">
      <!--      <router-link :to="{ name: 'StripePayment' }">-->
      <!--        <van-notice-bar v-if="status === 0" color="red"-->
      <!--                        left-icon="volume-o" >-->
      <!--          {{ $t("notice-bar.free") }}-->
      <!--        </van-notice-bar>-->
      <!--        <van-notice-bar v-else color="#1989fa"-->
      <!--                        left-icon="volume-o" >-->
      <!--          {{ $t("notice-bar.premium") }}-->
      <!--        </van-notice-bar>-->
      <!--      </router-link>-->
      <van-notice-bar
          color="red"
          left-icon="volume-o"
          @click="openEnergoUrl"
      >
        {{ $t("notice-bar.energo1") }}{{ this.appName }}{{ $t("notice-bar.energo2") }}
      </van-notice-bar>
      <template v-if="account">
        <div class="white-wrapper place-content-center mt-15 top-radius">
          <div class="apply-loader">
            <Image
              col="0"
              src="~/img/logo.png"
              width="300"
              marginTop="20"
              stretch="aspectFill"
              class="company-logo m-t-10"
            />
          </div>
        </div>
        <account
          :account="account"
          :show-balance="true"
          class="home-account-shadow place-content-center"
        />
        <itserve-route
          route-name="Pay"
          :params="$route.params"
          :buttonName="$t('buttons.pay')"
          type="wide"
          class="mt-15"
        ></itserve-route>
      </template>
      <div class="main-page-account place-content-center">
        <template v-if="account">
          <div @click="putIndications">
            <itserve-route
              :params="{
                account: $route.params.account,
              }"
              :buttonName="$t('buttons.putIndications')"
              type="wide"
              class="nav-btn mt-15-simple"
              :icon="true"
              icon-color="#3C2C93"
              btn-style="box"
            >
              <send-indications-icon />
            </itserve-route>
          </div>
        </template>
        <!--        <div @click="runPayment">-->
        <!--        <itserve-route-->

        <!--          :buttonName="$t('buttons.payAll')"-->
        <!--          type="wide"-->
        <!--          class="nav-btn mt-15-simple fr"-->
        <!--          :icon="true"-->
        <!--          icon-color="#1AAD62"-->
        <!--          btn-style="box"-->
        <!--        >-->
        <!--          <pay-icon />-->
        <!--        </itserve-route>-->
        <!--        </div>-->
        <itserve-route
          route-name="PaymentHistoryResults"
          :params="$route.params"
          :buttonName="$t('buttons.paymentHistory')"
          type="wide"
          class="nav-btn mt-15-simple mt-5 fr"
          :icon="true"
          icon-color="#000000"
          btn-style="box"
        >
          <payment-history-icon />
        </itserve-route>
        <div @click="runEasyPayAllService">
          <itserve-route
            :buttonName="$t('buttons.EasyPayAllService')"
            type="wide"
            class="nav-btn mt-15-simple mt-5"
            :icon="true"
            icon-color="#1531bd"
            btn-style="box"
          >
            <utility-icon />
          </itserve-route>
        </div>
        <!--        <div @click="navigateToExternalLink">-->
        <!--          <itserve-route-->
        <!--            :buttonName="$t('buttons.news24')"-->
        <!--            :params="$route.params"-->
        <!--            type="wide"-->
        <!--            class="nav-btn mt-15-simple"-->
        <!--            :icon="true"-->
        <!--            :newsIcon="true"-->
        <!--            btn-style="box"-->
        <!--          >-->
        <!--            <p style="margin-top: 15px">24</p>-->
        <!--          </itserve-route>-->
        <!--        </div>-->
        <!--        <template v-if="account">-->
        <!--          <itserve-route-->
        <!--            :route-name="-->
        <!--              account.accountVerified === 1 ? 'Shutdowns' : 'Verification'-->
        <!--            "-->
        <!--            :buttonName="$t('buttons.shutdowns')"-->
        <!--            :params="{-->
        <!--              account: $route.params.account,-->
        <!--              requested: account.accountVerified === 1 ? null : 'Shutdowns',-->
        <!--            }"-->
        <!--            type="wide"-->
        <!--            class="nav-btn mt-15-simple mt-5"-->
        <!--            :icon="true"-->
        <!--            icon-color="#FF8573"-->
        <!--          >-->
        <!--            <shutdown-icon />-->
        <!--          </itserve-route>-->
        <!--        </template>-->

        <div @click="socialShare">
          <itserve-route
            :buttonName="$t('buttons.socialShare')"
            type="wide"
            class="nav-btn mt-15-simple fr"
            :icon="true"
            icon-color="#0fb1e5"
            btn-style="box"
          >
            <share-icon size="85%" />
          </itserve-route>
        </div>
        <!--          <itserve-route-->
        <!--              route-name="StripePayment"-->
        <!--              :buttonName="$t('settings.stripeButtonNew')"-->
        <!--              :params="$route.params"-->
        <!--              type="wide"-->
        <!--              class="nav-btn mt-15-simple mt-5"-->
        <!--              :icon="true"-->
        <!--              icon-color="lightgreen"-->
        <!--              btn-style="box"-->
        <!--          >-->
        <!--            <dollar-sign-icon />-->
        <!--          </itserve-route>-->

        <itserve-route
          route-name="WriteTicket"
          :buttonName="$t('buttons.contactUs')"
          :params="$route.params"
          type="wide"
          class="nav-btn mt-15-simple mt-5"
          :icon="true"
          icon-color="#FCC204"
          btn-style="box"
        >
          <contactus-icon />
        </itserve-route>
        <!--        <itserve-route-->
        <!--          route-name=""-->
        <!--          :buttonName="$t('buttons.contactElectric')"-->
        <!--          :params="$route.params"-->
        <!--          type="wide"-->
        <!--          class="nav-btn mt-15-simple disabled-box"-->
        <!--          :icon="true"-->
        <!--          icon-color="#D08CFA"-->
        <!--        >-->
        <!--          <electric-icon />-->
        <!--        </itserve-route>-->
      </div>
    </van-col>
  </van-row>
</template>
<script>
import UtilityIcon from "@/components/icons/utility-icon";
import ItserveRoute from "@/components/content/ItserveRoute";
import Account from "@/components/helpers/Account";
// import PayIcon from "@/components/icons/pay-icon-box";
// import ShutdownIcon from "@/components/icons/shutdown-icon-box";
import ContactusIcon from "@/components/icons/contactus-icon-box";
import SendIndicationsIcon from "@/components/icons/send-indications-icon-box";
// import ElectricIcon from "@/components/icons/electric-icon-box";
import PaymentHistoryIcon from "@/components/icons/payment-history-icon-box";
// import ItserveButton from "@/components/forms/ItserveButton";
import ShareIcon from "vue-material-design-icons/Share";
// import DollarSignIcon from "@/components/icons/dollar-sign-icon";
import VanNoticeBar from "vant/lib/notice-bar/index";
export default {
  name: "Index",
  components: {
    UtilityIcon,
    // ItserveButton,
    SendIndicationsIcon,
    PaymentHistoryIcon,
    // ElectricIcon,
    ContactusIcon,
    // ShutdownIcon,
    // PayIcon,
    Account,
    ItserveRoute,
    ShareIcon,
    // DollarSignIcon,
    VanNoticeBar
  },
  data() {
    return {
      easyPayUrl: "https://easypay.ua/ua/partner-frame/serve/catalog/utility",
      appName: "",
      shareAppName: "",
    };
  },
  computed: {
    shareStatus() {
      console.log("USER SHARE STATUS: " + this.$store.state.Auth.share_status);
      return this.$store.state.Auth.share_status;
    },
    account() {
      return this.$store.state.Auth.account;
    },
    status() {
      console.log("USER STATUS: " + this.$store.state.Auth.status);
      return this.$store.state.Auth.status;
    },
    status_sub() {
      return this.$store.state.Auth.status_sub;
    },
  },
  created() {
    this.getPageData();
    this.getHistory();
    this.utilityUrl();
    this.noticeBarAppName();
    this.preloadInterstitial();
  },
  methods: {
    preloadInterstitial() {
      if (!this.$store.state.Auth.isPreloaded) {
        console.log("preloadInterstitial CALL!!!!");
        this.nativeSendEvent("preloadInterstitial");
        this.$store.state.Auth.isPreloaded = true;
      }
    },
    noticeBarAppName() {
      let id = this.$store.state.Auth.app_id;
      let locale = this.$store.state.Auth.locale;
      let text;

      switch (id) {
        case "zaporizhzhia.gas":
          text = this.getLocalizedText(locale, {
            uk: "Запоріжжя енерго",
            ru: "Запорожье энерго",
            en: "Zaporizhzhia energo",
          });
          break;
        case "lviv.gas":
          text = this.getLocalizedText(locale, {
            uk: "Львів енерго",
            ru: "Львов энерго",
            en: "Lviv energo",
          });
          break;
        case "volyn.gas":
          text = this.getLocalizedText(locale, {
            uk: "Волинь енерго",
            ru: "Волынь энерго",
            en: "Volyn energo",
          });
          break;
        case "kherson.gas":
          text = this.getLocalizedText(locale, {
            uk: "Херсон енерго",
            ru: "Херсон энерго",
            en: "Kherson energo",
          });
          break;
        case "ternopil.gas":
          text = this.getLocalizedText(locale, {
            uk: "Тернопіль енерго",
            ru: "Тернополь энерго",
            en: "Ternopil energo",
          });
          break;
        case "chernivtsi.gas":
          text = this.getLocalizedText(locale, {
            uk: "Чернівці енерго",
            ru: "Черновцы энерго",
            en: "Chernivtsi energo",
          });
          break;
        case "prykarpattya.gas":
          text = this.getLocalizedText(locale, {
            uk: "Прикарпаття енерго",
            ru: "Прикарпатье энерго",
            en: "Prykarpattya energo",
          });
          break;
        case "zakarpattya.gas":
          text = this.getLocalizedText(locale, {
            uk: "Закарпаття енерго",
            ru: "Закарпатье энерго",
            en: "Zakarpattya energo",
          });
          break;
        case "rivne.gas":
          text = this.getLocalizedText(locale, {
            uk: "Рівне енерго",
            ru: "Ровно энерго",
            en: "Rivne energo",
          });
          break;
        case "khmelnytskyi.gas":
          text = this.getLocalizedText(locale, {
            uk: "Хмельницький енерго",
            ru: "Хмельницкий энерго",
            en: "Khmelnytskyi energo",
          });
          break;
        case "vinnytsia.gas":
          text = this.getLocalizedText(locale, {
            uk: "Вінниця енерго",
            ru: "Винница энерго",
            en: "Vinnytsia energo",
          });
          break;
        case "cherkasy.gas":
          text = this.getLocalizedText(locale, {
            uk: "Черкаси енерго",
            ru: "Черкассы энерго",
            en: "Cherkasy energo",
          });
          break;
        case "chernihiv.gas":
          text = this.getLocalizedText(locale, {
            uk: "Чернігів енерго",
            ru: "Чернигов энерго",
            en: "Chernihiv energo",
          });
          break;
        case "mykolaiv.gas":
          text = this.getLocalizedText(locale, {
            uk: "Миколаїв енерго",
            ru: "Николаев энерго",
            en: "Mykolaiv energo",
          });
          break;
        case "dnipro.gas":
          text = this.getLocalizedText(locale, {
            uk: "Дніпро енерго",
            ru: "Днепр энерго",
            en: "Dnipro energo",
          });
          break;
        case "poltava.gas":
          text = this.getLocalizedText(locale, {
            uk: "Полтава енерго",
            ru: "Полтава энерго",
            en: "Poltava energo",
          });
          break;
        case "odesa.gas":
          text = this.getLocalizedText(locale, {
            uk: "Одеса енерго",
            ru: "Одесса энерго",
            en: "Odesa energo",
          });
          break;
        case "zhytomyr.gas":
          text = this.getLocalizedText(locale, {
            uk: "Житомир енерго",
            ru: "Житомир энерго",
            en: "Zhytomyr energo",
          });
          break;
        case "kirovograd.gas":
          text = this.getLocalizedText(locale, {
            uk: "Кіровоград енерго",
            ru: "Кировоград энерго",
            en: "Kirovohrad energo",
          });
          break;
        case "sumy.gas":
          text = this.getLocalizedText(locale, {
            uk: "Суми енерго",
            ru: "Сумы энерго",
            en: "Sumy energo",
          });
          break;
        case "kyiv.gas":
          text = this.getLocalizedText(locale, {
            uk: "Київ енерго",
            ru: "Киев энерго",
            en: "Kyiv energo",
          });
          break;
        case "kharkiv.gas":
          text = this.getLocalizedText(locale, {
            uk: "Харків енерго",
            ru: "Харьков энерго",
            en: "Kharkiv energo",
          });
          break;
        default:
          text = this.getLocalizedText(locale, {
            uk: "Харків енерго",
            ru: "Харьков энерго",
            en: "Kharkiv energo",
          });
      }
      console.log("LOCALE:", this.$store.state.Auth.locale)
      this.appName = text;
    },
    getLocalizedText(locale, links) {
      switch (locale) {
        case "uk":
          return links.uk;
        case "ru":
          return links.ru;
        case "en":
          return links.en;
        default:
          return links.uk; // значение по умолчанию, если язык не найден
      }
    },
    openEnergoUrl() {
      let id;
      let url;

      id = this.$store.state.Auth.app_id;
      switch (id) {
        case "zaporizhzhia.gas":
          url = "https://billing.city/zaporizhzhia.energo";
          break;
        case "lviv.gas":
          url = "https://billing.city/lviv.energo";
          break;
        case "volyn.gas":
          url = "https://billing.city/volyn.energo";
          break;
        case "kherson.gas":
          url = "https://billing.city/kherson.energo";
          break;
        case "ternopil.gas":
          url = "https://billing.city/ternopil.energo";
          break;
        case "chernivtsi.gas":
          url = "https://billing.city/chernivtsi.energo";
          break;
        case "prykarpattya.gas":
          url = "https://billing.city/prykarpattya.energo";
          break;
        case "zakarpattya.gas":
          url = "https://billing.city/zakarpattya.energo";
          break;
        case "rivne.gas":
          url = "https://billing.city/rivne.energo";
          break;
        case "khmelnytskyi.gas":
          url = "https://billing.city/khmelnytskyi.energo";
          break;
        case "vinnytsia.gas":
          url = "https://billing.city/vinnytsia.energo";
          break;
        case "cherkasy.gas":
          url = "https://billing.city/cherkasy.energo";
          break;
        case "chernihiv.gas":
          url = "https://billing.city/chernihiv.energo";
          break;
        case "mykolaiv.gas":
          url = "https://billing.city/mykolaiv.energo";
          break;
        case "dnipro.gas":
          url = "https://billing.city/dnipro.energo";
          break;
        case "poltava.gas":
          url = "https://billing.city/poltava.energo";
          break;
        case "odesa.gas":
          url = "https://billing.city/odesa.energo";
          break;
        case "zhytomyr.gas":
          url = "https://billing.city/zhytomyr.energo";
          break;
        case "kirovograd.gas":
          url = "https://billing.city/kirovograd.energo";
          break;
        case "sumy.gas":
          url = "https://billing.city/sumy.energo";
          break;
        case "kyiv.gas":
          url = "https://billing.city/kyiv.energo";
          break;
        case "kharkiv.gas":
          url = "https://billing.city/kharkiv.kharenergo2";
          break;
      }
      console.log("URL: ", url)
      this.nativeSendEvent("url", url);
    },
    async putIndications() {
      if (this.shareStatus === 1) {
        await this.$router.push({
          name: "RegionalEnergy",
          params: this.$route.params,
        });
      } else {
        await this.$router.push({
          name: "ShareAppBeforePutIndication",
          params: this.$route.params,
        });
      }
    },
    async runEasyPayAllService() {
      if (this.shareStatus === 1) {
        console.log("CHLENIX!!!");
        await this.$router.push({ name: "EasyPayAllServicePage",
          params: { easyPayUrl: this.easyPayUrl },
        });
      } else {
        await this.$router.push({
          name: "ShareApp",
          params: { easyPayUrl: this.easyPayUrl },
        });
      }
    },
    getLS() {
      return {
        ls: this.$route.params.account,
      };
    },
    async getHistory() {
      await this.$store.commit("PaymentHistory/SET_LS", this.getLS());
      await this.$store.dispatch("Auth/getStripeStatus");
      await this.$store.dispatch("PaymentHistory/getPaymentHistory");
    },
    async getPageData() {
      await this.checkForCorrectParameters();
      await this.$store.dispatch("Payment/getCardsList");
      await this.$store.dispatch("Settings/getSettings");
      await this.$store.dispatch("Auth/getSubscriptionStatus");
      await this.$store.dispatch("Auth/getUserLS", this.account);
    },
    navigateToExternalLink() {
      this.nativeSendEvent("url", "https://t.me/napulsi24");
    },
    async checkForCorrectParameters() {
      if (Object.keys(this.$route.params).length) {
        if (this.$route.params.account !== undefined) {
          await this.$store.dispatch(
            "Auth/getUserAccount",
            this.$route.params.account
          );
        } else {
          console.error(
            "WARNING: somehow user opened account page without provided account parameter!"
          );
          await this.$router.push({ name: "Home" });
        }
      } else {
        console.error(
          "WARNING: somehow user opened account page any parameters!"
        );
        await this.$router.push({ name: "Home" });
      }
    },
    socialShare() {
      let id;
      let url;
      let text;
      let locale;

      locale = this.$store.state.Auth.locale;
      id = this.$store.state.Auth.app_id;
      switch (id) {
        case "zaporizhzhia.gas":
          url = "https://billing.city/zaporizhzhia.gas";
          text = this.getLocalizedText(locale, {
            uk: "Запоріжжя газ",
            ru: "Запорожье газ",
            en: "Zaporizhzhia gas",
          });
          break;
        case "lviv.gas":
          url = "https://billing.city/lviv.gas";
          text = this.getLocalizedText(locale, {
            uk: "Львів газ",
            ru: "Львов газ",
            en: "Lviv gas",
          });
          break;
        case "volyn.gas":
          url = "https://billing.city/volyn.gas";
          text = this.getLocalizedText(locale, {
            uk: "Волинь газ",
            ru: "Волынь газ",
            en: "Volyn gas",
          });
          break;
        case "kherson.gas":
          url = "https://billing.city/kherson.gas";
          text = this.getLocalizedText(locale, {
            uk: "Херсон газ",
            ru: "Херсон газ",
            en: "Kherson gas",
          });
          break;
        case "ternopil.gas":
          url = "https://billing.city/ternopil.gas";
          text = this.getLocalizedText(locale, {
            uk: "Тернопіль газ",
            ru: "Тернополь газ",
            en: "Ternopil gas",
          });
          break;
        case "chernivtsi.gas":
          url = "https://billing.city/chernivtsi.gas";
          text = this.getLocalizedText(locale, {
            uk: "Чернівці газ",
            ru: "Черновцы газ",
            en: "Chernivtsi gas",
          });
          break;
        case "prykarpattya.gas":
          url = "https://billing.city/prykarpattya.gas";
          text = this.getLocalizedText(locale, {
            uk: "Прикарпаття газ",
            ru: "Прикарпатье газ",
            en: "Prykarpattya gas",
          });
          break;
        case "zakarpattya.gas":
          url = "https://billing.city/zakarpattya.gas";
          text = this.getLocalizedText(locale, {
            uk: "Закарпаття газ",
            ru: "Закарпатье газ",
            en: "Zakarpattya gas",
          });
          break;
        case "rivne.gas":
          url = "https://billing.city/rivne.gas";
          text = this.getLocalizedText(locale, {
            uk: "Рівне газ",
            ru: "Ровно газ",
            en: "Rivne gas",
          });
          break;
        case "khmelnytskyi.gas":
          url = "https://billing.city/khmelnytskyi.gas";
          text = this.getLocalizedText(locale, {
            uk: "Хмельницький газ",
            ru: "Хмельницкий газ",
            en: "Khmelnytskyi gas",
          });
          break;
        case "vinnytsia.gas":
          url = "https://billing.city/vinnytsia.gas";
          text = this.getLocalizedText(locale, {
            uk: "Вінниця газ",
            ru: "Винница газ",
            en: "Vinnytsia gas",
          });
          break;
        case "cherkasy.gas":
          url = "https://billing.city/cherkasy.gas";
          text = this.getLocalizedText(locale, {
            uk: "Черкаси газ",
            ru: "Черкассы газ",
            en: "Cherkasy gas",
          });
          break;
        case "chernihiv.gas":
          url = "https://billing.city/chernihiv.gas";
          text = this.getLocalizedText(locale, {
            uk: "Чернігів газ",
            ru: "Чернигов газ",
            en: "Chernihiv gas",
          });
          break;
        case "mykolaiv.gas":
          url = "https://billing.city/mykolaiv.gas";
          text = this.getLocalizedText(locale, {
            uk: "Миколаїв газ",
            ru: "Николаев газ",
            en: "Mykolaiv gas",
          });
          break;
        case "dnipro.gas":
          url = "https://billing.city/dnipro.gas";
          text = this.getLocalizedText(locale, {
            uk: "Дніпро газ",
            ru: "Днепр газ",
            en: "Dnipro gas",
          });
          break;
        case "poltava.gas":
          url = "https://billing.city/poltava.gas";
          text = this.getLocalizedText(locale, {
            uk: "Полтава газ",
            ru: "Полтава газ",
            en: "Poltava gas",
          });
          break;
        case "odesa.gas":
          url = "https://billing.city/odesa.gas";
          text = this.getLocalizedText(locale, {
            uk: "Одеса газ",
            ru: "Одесса газ",
            en: "Odesa gas",
          });
          break;
        case "zhytomyr.gas":
          url = "https://billing.city/zhytomyr.gas";
          text = this.getLocalizedText(locale, {
            uk: "Житомир газ",
            ru: "Житомир газ",
            en: "Zhytomyr gas",
          });
          break;
        case "kirovograd.gas":
          url = "https://billing.city/kirovograd.gas";
          text = this.getLocalizedText(locale, {
            uk: "Кіровоград газ",
            ru: "Кировоград газ",
            en: "Kirovohrad gas",
          });
          break;
        case "sumy.gas":
          url = "https://billing.city/sumy.gas";
          text = this.getLocalizedText(locale, {
            uk: "Суми газ",
            ru: "Сумы газ",
            en: "Sumy gas",
          });
          break;
        case "kyiv.gas":
          url = "https://billing.city/kyiv.gas";
          text = this.getLocalizedText(locale, {
            uk: "Київ газ",
            ru: "Киев газ",
            en: "Kyiv gas",
          });
          break;
        case "kharkiv.gas":
          url = "https://billing.city/kharkiv.gas";
          text = this.getLocalizedText(locale, {
            uk: "Харків газ",
            ru: "Харьков газ",
            en: "Kharkiv gas",
          });
          break;
      }
      console.log("SHARE URL: ", url)
      this.shareAppName = text;
      this.nativeSendEvent("socialShare", {
        text: this.$t("settings.title1") + this.shareAppName + this.$t("settings.title2") + url,
        subject: this.shareAppName,
      });
    },
    utilityUrl() {
      let id;
      let url;

      id = this.$store.state.Auth.app_id;
      switch (id) {
        case "zaporizhzhia.gas":
          url =
            "https://easypay.ua/ua/partner-frame/serve/catalog/utility/zaporizhia";
          break;
        case "lviv.gas":
          url =
            "https://easypay.ua/ua/partner-frame/serve/catalog/utility/lvivska-obl";
          break;
        case "volyn.gas":
          url =
            "https://easypay.ua/ua/partner-frame/serve/catalog/utility/lutsk";
          break;
        case "kherson.gas":
          url =
            "https://easypay.ua/ua/partner-frame/serve/catalog/utility/kherson";
          break;
        case "ternopil.gas":
          url =
            "https://easypay.ua/ua/partner-frame/serve/catalog/utility/ternopol";
          break;
        case "chernivtsi.gas":
          url =
            "https://easypay.ua/ua/partner-frame/serve/catalog/utility/chernivtsi";
          break;
        case "prykarpattya.gas":
          url =
            "https://easypay.ua/ua/partner-frame/serve/catalog/utility/ivano-frank-obl";
          break;
        case "zakarpattya.gas":
          url =
            "https://easypay.ua/ua/partner-frame/serve/catalog/utility/zakarpattya";
          break;
        case "rivne.gas":
          url =
            "https://easypay.ua/ua/partner-frame/serve/catalog/utility/rivne";
          break;
        case "khmelnytskyi.gas":
          url =
            "https://easypay.ua/ua/partner-frame/serve/catalog/utility/hmelnickiy";
          break;
        case "vinnytsia.gas":
          url =
            "https://easypay.ua/ua/partner-frame/serve/catalog/utility/vinnica";
          break;
        case "cherkasy.gas":
          url =
            "https://easypay.ua/ua/partner-frame/serve/catalog/utility/cherkasy";
          break;
        case "chernihiv.gas":
          url =
            "https://easypay.ua/ua/partner-frame/serve/catalog/utility/chernihiv";
          break;
        case "mykolaiv.gas":
          url =
            "https://easypay.ua/ua/partner-frame/serve/catalog/utility/mykolaiv";
          break;
        case "dnipro.gas":
          url =
            "https://easypay.ua/ua/partner-frame/serve/catalog/utility/dnepropetrovsk";
          break;
        case "poltava.gas":
          url =
            "https://easypay.ua/ua/partner-frame/serve/catalog/utility/poltava";
          break;
        case "odesa.gas":
          url =
            "https://easypay.ua/ua/partner-frame/serve/catalog/utility/odessa";
          break;
        case "zhytomyr.gas":
          url =
            "https://easypay.ua/ua/partner-frame/serve/catalog/utility/zhytomyrska-oblast";
          break;
        case "kirovograd.gas":
          url =
            "https://easypay.ua/ua/partner-frame/serve/catalog/utility/kirovograd";
          break;
        case "sumy.gas":
          url =
            "https://easypay.ua/ua/partner-frame/serve/catalog/utility/sumy";
          break;
        case "kyiv.gas":
          url =
            "https://easypay.ua/ua/partner-frame/serve/catalog/utility/kiev";
          break;
        case "kharkiv.gas":
          url =
            "https://easypay.ua/ua/partner-frame/serve/catalog/utility/harkov";
          break;
        default:
          url = "https://easypay.ua/ua/partner-frame/serve/catalog/utility";
      }
      this.easyPayUrl = url;
    },
  },
};
</script>
<style scoped>
@import "../../../node_modules/vant/lib/dialog/index.css";
@import "../../../node_modules/vant/lib/overlay/index.css";
@import "../../../node_modules/vant/lib/popup/index.css";
.main-page-account {
  padding-bottom: 45px;
}
.page-type {
  padding: 15px 0 0 0;
  font-size: 18px;
  margin: 0;
  color: #0b0b0b;
  background-color: #fff;
}
.home-account-shadow {
  border-radius: 0 0 15px 15px;
  -webkit-box-shadow: 0 6px 4px 0 rgba(34, 60, 80, 0.17);
  -moz-box-shadow: 0 6px 4px 0 rgba(34, 60, 80, 0.17);
  box-shadow: 0 6px 4px 0 rgba(34, 60, 80, 0.17);
}
.top-radius {
  border-radius: 15px 15px 0 0;
}
</style>
